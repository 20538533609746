window.loaded = false;
var windowWidth = 0;

$(window).on('load', function(){
	window.loaded = true;
});

var headerBorder = undefined;
var carteContent = undefined;
var contactMap = undefined;
var parallaxImages = undefined;
var footerParallaxImage = undefined;

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top}, 400);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			headerBorder = $('[data-purpose="header-scroll-border"]');
			if (headerBorder.length == 0) {
				headerBorder = $('.main-header');
			}
			var menuPanel = $('[data-purpose="floating-menu"]');
			if (menuPanel && menuPanel.length > 0) {
				var scrollFn = function() {
					if (headerBorder && headerBorder.length > 0) {
						var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
						var scrollTop = $(window).scrollTop();
						if (height <= scrollTop && menuPanel.hasClass('inactive')) {
							menuPanel.removeClass('inactive');
						} else if (height > scrollTop && !menuPanel.hasClass('inactive')) {
							menuPanel.addClass('inactive');
						}
					}
				};

				window.addEventListener('touchend', scrollFn);
				window.addEventListener('touchmove', scrollFn);
				window.addEventListener('scroll', scrollFn);
			}

			parallaxImages = $('.parallax-image');
			if (parallaxImages.length > 0) {
				var scrollParallax = function() {
					parallaxImages.each(function(){
						setImageParallaxBackground($(this));
					});
				};
				window.addEventListener('scroll', scrollParallax);
			}
		} else if (site_id == 8) {
			initContactMap();
		} else if (site_id == 7) {
			$('a.gallery-item').click(function(){
				openGallery($(this));
			});
		}
	}

	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

});

function setImageParallaxBackground(image) {
	if (image) {
		var isMobile = window.innerWidth <= 600
		var log = false; //image.hasClass('home-illustration-4');
		var scrollTop = $(window).scrollTop();
		var top = image.offset().top;
		var move = 0;
		var step = 0.04;
		var distance = image.data('distance');
		if (!distance) {
			distance = 200;
		} else {
			distance = parseInt(distance);
		}
		
		var startPoint = top - distance;
		if (log) {
			console.log("top:"+ top);
			console.log("startPoint: "+(top - distance));
		}
		
				
		move = (scrollTop - startPoint) * step;
		
		if(isMobile || move <= 0) {
			move = 0;
		} else if (move >= 40) {
			move =  40;
		} 

		
		
		move *= -1;
		var translateX = image.data('translate-x');
		if (isMobile) {
			translateX = '0px';
		}
		
		image.attr('style', 'background-image: url('+image.data('background')+');transform:translate('+translateX+', '+move+'%);');
	}
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}

function initContactMap() {
	if (mapboxgl.supported()) {
		mapboxgl.accessToken = 'pk.eyJ1Ijoic2VydWthIiwiYSI6ImNqNmhxbjF2ejA5YXAzMnRlN3o4ZjBhdTQifQ.Wj8inzuV9QHNCovlvPucSg';
		
		contactMap = new mapboxgl.Map({
				container: 'contactMap',
				style: 'mapbox://styles/seruka/cjepuixvvgyml2rpdtkaqmclh',
				center: [21.40245, 47.45155],
				zoom: 14.5
		});

		contactMap.scrollZoom.disable();

		contactMap.on('load', function(){
			var el = document.createElement('div');
			el.className = 'map-marker';
			el.innerHTML = '<span class="marker-outer">'+svg('marker-icon')+'</span>';

			new mapboxgl.Marker(el)
				.setLngLat([21.40245, 47.45155])
				.addTo(contactMap);
		});
	}
}

function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function getMapStyle()
{
	return [];
}

