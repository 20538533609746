(function(window){
    function BookingForm(form, params) {
        try {
            if (!form || (form && form.length == 0)) {
                throw 'Form is required!';
            } else if (form.length > 1) {
                throw 'Form must be unique element!';
            } else if (!form.is('form')) {
                throw 'Form must be a form element!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    BookingForm.prototype = {
        form: undefined,
        button: undefined,
        datepicker: undefined,
        timeSelectParent: undefined,
        timeSelect: undefined,
        dateField: undefined,
        confirmContent: undefined,
        fullDays: [],
        sendedForm: false,
        notyf: undefined,
        container: undefined,
        selectors: {
            button: '[data-purpose="send-form"]',
            datepicker: '#datepickerOuter',
            timeselect: '[data-purpose="time-select"]',
            datefield: '[type="hidden"][name="date"]',
            confirm_content: '[data-purpose="confirm-content"]',
        },
        init: function() {
            this.setElements();
            this.initDatepicker();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find(this.getSelector('button'));
            this.timeSelectParent = this.form.find(this.getSelector('timeselect'));
            this.timeSelect = this.timeSelectParent.find('select');
            this.datepicker = this.form.find(this.getSelector('datepicker'));
            this.dateField = this.form.find(this.getSelector('datefield'));
            this.container = this.form.parent();
            this.confirmContent = this.container.find(this.getSelector('confirm_content'));

            this.notyf = new Notyf({
                delay: 4000,
                alertIcon: 'notyf-error-icon',
                confirmIcon: 'notyf-confirm-icon'
            });
        },
        initDatepicker: function() {
            var _self = this;
            if (typeof frontend_lang !== 'undefined' && frontend_lang == 'hu') {
				$.datepicker.setDefaults( $.datepicker.regional[ "hu" ] );
			}
			this.datepicker.datepicker({
				dateFormat:"yy-mm-dd",
				firstDay:1,
				nextText: svg('arrow-right'),
				prevText: svg('arrow-right'),
				minDate:'+1',
                hideIfNoPrevNext: true,
                afterInit: function(inst) {
                    _self.markFullDays();
                },
                onSelect: function(date, inst) {
                    var oldDate = _self.dateField.val();
                    var changed = true;
                    if (oldDate == date) {
                        changed = false;
                    }
                    _self.dateField.val(date);
                    _self.markFullDays();
                    _self.loadTimeOfDate(date, changed);
                },
                onChangeMonthYear: function(year, month, inst) {
                    _self.setFullDays([]);
                    _self.loadExclusionDays(year, month);
                },
                afterSelect: function() {
                    _self.markFullDays();
                }
			});
        },
        addDOMEvents: function() {
            var _self = this;
            if (this.button) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            this.form.on('submit', function(e) {
                e.preventDefault();
            });

            this.form.find('input[type="text"]').on('keyup',function(){
                if ($(this).hasClass('has-error') && $(this).val().trim() !== '') {
                    $(this).removeClass('has-error');
                }
            });

            this.form.find('select').on('change', function(){
                var parent = $(this).parent();
                if (parent.hasClass('has-error')) {
                    var val = $(this).val();
                    if (val != 0 && val != '00:00') {
                        parent.removeClass('has-error');
                    }
                }
            });
        },
        setParams: function(params) {
            if (params.fullDays) this.setFullDays(params.fullDays);
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url:ajax_controller+'send_table_booking',
                    dataType: 'json',
                    type:'post',
                    data: this.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.form.remove();
                            _self.confirmContent.show();
                        } else {
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(name, text){
                                    if (text && text.trim() !== '') {
                                        _self.notyf.alert(text);
                                    }

                                    var field = _self.form.find('[name="'+name+'"]');
                                    if (field.length > 0) {
                                        if (field.is('select')) {
                                            field.parent().addClass('has-error');
                                        } else {
                                            field.addClass('has-error');
                                        }
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 2000);
                    }
                });
            }
        },
        setFullDays: function(days) {
            if (typeof days === 'object' && Array.isArray(days)) {
                this.fullDays = days;
            } else {
                this.fullDays = [];
            }
        },
        loadTimeOfDate: function(date, changed) {
            var _self = this;

            $.ajax({
                url:ajax_controller+'get_free_times',
                dataType:'json',
                type:'post',
                data: {
                    date
                },
                success: function(response) {
                    _self.setOpeningTimes(response.times, changed);
                }
            });
        },
        loadExclusionDays: function(year, month) {
            var _self = this;
            
            $.ajax({
                url:ajax_controller+'get_exclusion_days',
                dataType:'json',
                type:'post',
                data: {
                    year, month
                },
                success: function(response) {
                    _self.setFullDays(response.days);
                    _self.markFullDays();
                }
            });
        },
        markFullDays: function() {
            if (Array.isArray(this.fullDays) && this.fullDays.length > 0) {
                for (var i = 0; i < this.fullDays.length; i++) {
                    var day = this.fullDays[i];
                    var cell = this.datepicker.find('td[data-day="'+day+'"]');
                    if (cell.length == 1) {
                        cell.addClass('ui-state-unselectable full-day');
                    }
                }
            }
        },
        getSelector: function(key) {
            if (typeof this.selectors[key] === 'undefined') {
                throw key+' selector is undefined!';
            }

            return this.selectors[key];
        },
        setOpeningTimes: function(times, changed) {
            var currentValue = !changed ? this.timeSelect.val() : false;
            this.timeSelect.html('');
            if (Array.isArray(times) && times.length > 0) {
                this.timeSelect.removeAttr('disabled');
                this.timeSelectParent.removeClass('disabled');
                this.timeSelectParent.removeClass('has-error');
                for (var i = 0; i < times.length; i++) {
                    var time = times[i];
                    this.timeSelect.append('<option value="'+time+'" '+(currentValue && currentValue == time ? 'selected' : '')+'>'+time+'</option>');
                }
            } else {
                this.timeSelect.attr('disabled', 'disabled');
                this.timeSelectParent.addClass('disabled');
                this.timeSelect.html('<option value="00:00">00:00</option>');
            }
        }
    };

    window.BookingForm = BookingForm;
})(window);
